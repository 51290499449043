import { BadRequestError } from '@/data/protocols/errors'
import { HttpPatchClient, HttpResponse, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import {
  SaveConsultantInfo,
  SaveConsultantInfoParams,
  SaveConsultantInfoRequestHeaders,
  SaveConsultantInfoResponse,
  UpdatePersonBody,
} from '@/domain/use-cases/register-maintenance/save-consultant-info'
import { FACELIFT_WEB_SOURCESYSTEM_ID } from '@/utils/constants'

export class RemoteSaveConsultantInfo implements SaveConsultantInfo {
  constructor(
    private readonly url: string,
    private readonly httpPatchClient: HttpPatchClient<
      SaveConsultantInfoRequestHeaders,
      UpdatePersonBody,
      SaveConsultantInfoResponse
    >,
    private readonly xApiKey: string
  ) {}

  update = async (params: SaveConsultantInfoParams): Promise<SaveConsultantInfoResponse> => {
    const {
      personId,
      requesterPersonId,
      emails,
      phones,
      name,
      nickname,
      gender,
      birthday,
      motherName,
      companyId,
      additionalInformation,
      countryId,
      businessModel,
      role,
      functionId
    } = params

    const response = await this.httpPatchClient.patch({
      url: `${this.url}/${personId}`,
      headers: {
        'x-api-key': this.xApiKey,
        companyid: companyId,
        countryid: countryId,
        sourcesystem: FACELIFT_WEB_SOURCESYSTEM_ID,
        requesterPersonId,
        businessmodel: businessModel,
        functionid: functionId,
        role
      },
      body: {
        emails,
        phones,
        name,
        nickname,
        gender,
        birthday,
        additionalInformation,
        motherName,
      },
    })

    return this.handleResponse(response)
  }

  updateBasicData = async (params: SaveConsultantInfoParams): Promise<SaveConsultantInfoResponse> => {
    const {
      personId,
      requesterPersonId,
      emails,
      phones,
      name,
      nickname,
      gender,
      birthday,
      motherName,
      companyId,
      countryId,
      businessModel,
      role,
      functionId
    } = params
  

    const response = await this.httpPatchClient.patch({
      url: `${this.url}/${personId}`,
      headers: {
        'x-api-key': this.xApiKey,
        companyid: companyId,
        countryid: countryId,
        sourcesystem: FACELIFT_WEB_SOURCESYSTEM_ID,
        requesterPersonId,
        businessmodel: businessModel,
        role,
        functionid: functionId,
      },
      body: {
        emails,
        phones,
        name,
        nickname,
        gender,
        birthday,
        motherName,
      },
    })
    return this.handleResponse(response)
  }

  private handleResponse(response: HttpResponse) {
    const { statusCode, body } = response
    switch (statusCode) {
      case HttpStatusCode.ok:
        if (body?.message === 'Invalid request schema'){
          throw new BadRequestError(body.message)
        }
        return body
      case HttpStatusCode.noContent:
        return body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(body.error)
      default:
        throw new UnexpectedError()
    }
  }
}
