import { validate as rutValidator } from 'rut.js'
import { AddressPageForms } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { createPhoneNumberFirstDigitValidation } from '@/main/pages/personal-data/fields'
import { capitalizeWords } from '@/utils/capitalize-words'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'
import { AgreementProduct } from '../../agreement/agreement-product'
import { Document } from '../../documents/documents'
import { AttachmentType } from '../../attachments/attachments'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { VendorId } from '../../vendor/vendor-id'
import { AddressPageType, AddressOrder } from '../../address'
import { PhoneType } from '../../person'
import { companyIconName } from '../../company/company-icon-name'
import { CompanyName } from '../../company/company-name'

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.Rut,
      validChars: /^[\d{7,9}\dkK]{0,10}$/m,
      name: 'RUT',
      rules: {
        pattern: /[\d{7,9}\dkK]{0,10}/,
        minLength: 8,
        maxLength: 10,
        validate: (value: string) => rutValidator(value),
      },
      isUpperCase: true,
    },
  ],
}

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: true,
  agreementProducts: [AgreementProduct.NATURA],
  shouldDisplayEstablishmentInfo: true,
}

const authorizationCode: AuthorizationCodeConfigs = {
  phoneNumberHyphenIndex: undefined,
}

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.DEFAULT,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const address: AddressConfigs = {
  pageType:
    process.env.CHILE_ADDRESS_PAGE_STATELESS_TOGGLE === 'true'
      ? AddressPageType.GeographicalLevels
      : AddressPageType.Legacy,
  shouldDisableTheFilledInput: false,
  geographicalLevels: { length: 2 },
  neighborhood: {
    rules: {
      maxLength: 30
    },
  },
  complement:{
    rules: {
      maxLength: 50
    },
  },
  number: {
    validChars: null,
    hasNoNumberCheckbox: false,
    rules: {
      maxLength: 6
    },
  },
  street:{
    maxLength: 50
  },
  reference: {
    maxLength: 100
  },
  showDeliveryCheckbox: true,
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ],
  showAlertOnApprovedPage: true,
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.Rut,
      name: 'Carnet de Identidad',
      attachments: [
        { title: 'Frente', type: AttachmentType.RutFront },
        { title: 'Reverso', type: AttachmentType.RutBack },
      ],
    },
  ],
  canChooseDocument: false,
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: true,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  goToClickButton: {
    show: false,
  },
  companyIconName: companyIconName[CompanyName.Natura],
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = {
  phoneNumberAreaCode: '56',
  decorativeBoxInTokenValidation: true,
  phoneNumberOptions: {
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    maxLength: 9,
    minLength: 9,
  },
  phoneNumberSecondaryOptions: {
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    maxLength: 9,
    minLength: 9,
  },
  shouldEditEmail: true,
  shouldEditPhone: true,
  showPhoneNumberSecondary: true,
  tenantTokenSecurityToggle: true,
  // telephonesOptions: [
  //   {
  //     type: 1,
  //     mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
  //     validation: /^(9[0-9]{8})$/,
  //     minLength: 9,
  //     maxLength: 9,
  //   },
  //   {
  //     type: 2,
  //     mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
  //     validation: /^(9[0-9]{8})$/,
  //     minLength: 9,
  //     maxLength: 9,
  //   },
  //   {
  //     type: 5,
  //     mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
  //     validation: /^(9[0-9]{8})$/,
  //     minLength: 9,
  //     maxLength: 9,
  //   },
  //   {
  //     type: 11,
  //     mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
  //     validation: /^(9[0-9]{8})$/,
  //     minLength: 9,
  //     maxLength: 9,
  //   },
  //   {
  //     type: 9,
  //     mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
  //     validation: /^(9[0-9]{8})$/,
  //     minLength: 9,
  //     maxLength: 9,
  //   },
  // ]
}

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const personalData: PersonalDataConfigs = {
  shouldUsePhoneSelect: true,
  showAsteriskInRequiredLabel: false,
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      firstDigitValidation: createPhoneNumberFirstDigitValidation('9'),
      length: 9,
    },
    {
      type: PhoneType.LINE,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 8,
    },
  ],
  shouldUseSeparatedNames: process.env.SHOULD_USE_SEPARATED_NAMES === 'true',
  namesOptions: ['firstName', 'lastName'],
  allowedCharsRegex: new RegExp(/^[a-zA-Z ]+$/),
  allocationStrategy: { levels: { length: 2 } },
}

const profile: ProfileConfigs = {
  showMyLeader: true,
  showCnCredit: true,
  contact: {
    phoneMask: {
      maskRegex: /^(\d{5})(\d{4}).*/,
      mask: '$1 $2',
    },
    editUrl: '/mfe-register/maintenanceRegisterPage/',
    minegocioEditUrl: '/mfe-register/maintenanceRegisterPage/',
  },
  consultantData: {
    editUrl: '/mfe-register/maintenancePersonalDataPage/',
    minegocioEditUrl: '/mfe-register/maintenancePersonalDataPage',
  },
  digitalSpace: {
    digitalSpaceBaseUrl: 'https://www.natura.com.br/consultoria/',

    mySpaceDashboard:
      'https://cadastro-experiencia-digital-store-fe.qa.naturacloud.com/?country=cl&company=natura',
    openDigitalSpaceUrl:
      'https://cadastro-experiencia-digital-store-fe.qa.naturacloud.com/?country=cl&company=natura',
    showDigitalSpace: false
  },
  address: {
    getAddress: ({ street, number, complement, level1, level2, state }: AddressPageForms) => {
      const address = [
        street, number, complement, level1?.label, level2?.label
      ].filter(Boolean).join(', ')
      const showState = state ? ` - ${state.toUpperCase()}` : ''

      const capitalizedAddress = capitalizeWords(address)
      return `${capitalizedAddress} ${showState}`
    },
    editUrl: '/mfe-register/maintenanceAddressPage/',
    minegocioEditUrl: '/mfe-register/maintenanceAddressPage',
  },
  hasEditProfile: false,
  hasSelfDeclaration: false,
  hasGender: false,
  hasSocialName: false,
  showPhoneNumberSecondary: true,
  showGroupSectorLeader: true,
  showEditContact: true,
  showEditAddress: false,
  showGrowthPlan: true,
  mgmBanner: {
    show: true,
    showToConsultant: true,
    showToLeader: true,
    showToManager: false,
    image: 'mgm_thumbnail_cl.png',
  },
}

export const naturaChlConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  address,
  digitalStore,
  digitalSignaturePage: null,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
