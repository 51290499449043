import { MyProfileMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { ProfileConfigs } from '@/domain/models/tenant/tenant-configs'
import { countryCompanyToTenantIdMapper, TenantId } from '@/domain/models/tenant/tenant-id'
import { PersonDataResponse } from '@/domain/use-cases/person'
import { Icon, Tooltip } from '@/main/components'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { getFormattedPhoneNumber } from '@/main/pages'
import { IconButton, Typography } from '@naturacosmeticos/natds-web'
import React, { useContext } from 'react'
import { CustomCard } from '../components/custom-card'
import { useContactStyles } from './contact.styles'

export enum PhoneNumberOrder {
  primary = 0,
  secondary = 1,
}

export type ProfileProps = {
  personData: PersonDataResponse
  showEditButton?: boolean
}

const SOURCESYSTEM_MINEGOCIO = '50'

export const ProfileContact: React.FC<ProfileProps> = ({ showEditButton, personData: { person } }) => {
  const { emails, phones, personId } = person

  const componentClasses = useContactStyles()
  const { companyId, countryId, sourceSystem } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const isCountryEcuador = tenantId === TenantId.NaturaECU

  const {
    contact: {
      phoneMask: { maskRegex, mask },
      editUrl,
      minegocioEditUrl,
    },
    showPhoneNumberSecondary,
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const { contact, editButton: editButtonName } = usePageMessages(Page.MyProfile)
    .messages as MyProfileMessages

  let editButtonLink = ''
  if (sourceSystem && sourceSystem === SOURCESYSTEM_MINEGOCIO) {
    editButtonLink = `${minegocioEditUrl}${person.approvedCandidate.candidateId}`
  } else {
    editButtonLink = `${editUrl}${personId}`
  }

  const [phoneNumberPrimary, phoneNumberSecondary] = phones

  const phoneNumberFormatted = (number, maskRegex, mask) => {
    if (isCountryEcuador) {
      const numberFormatted = getFormattedPhoneNumber(number)?.padStart(10, '0')
      return numberFormatted?.replace(maskRegex, mask) || '-'
    }

    return getFormattedPhoneNumber(number)?.replace(maskRegex, mask) || '-'
  }
  return (
    <CustomCard
      title={contact.title}
      className={componentClasses.container}
      editButtonName={showEditButton && editButtonName}
      editButtonLink={showEditButton && editButtonLink}
    >
      <div>
        <div className={componentClasses.contactContainer}>
          <Typography variant="body2" color="textSecondary">
            {contact.phone}
          </Typography>
          <div className={componentClasses.contactPhoneContent}>
            <Typography variant="body1">
              {phoneNumberFormatted(phoneNumberPrimary, maskRegex, mask)}
            </Typography>
            <TooltipButtonIcon
              phone={phoneNumberPrimary}
              formattedPhone={phoneNumberFormatted(phoneNumberPrimary, maskRegex, mask)}
              componentClasses={componentClasses}
              title={contact.tooltipPhone}
            />
          </div>
        </div>

        {showPhoneNumberSecondary && !!phoneNumberSecondary && (
          <div className={componentClasses.contactContainer}>
            <Typography variant="body2" color="textSecondary">
              {contact.secondaryPhoneLabel}
            </Typography>
            <div className={componentClasses.contactPhoneContent}>
              <Typography variant="body1">
                {phoneNumberFormatted(phoneNumberSecondary, maskRegex, mask)}
              </Typography>
              <TooltipButtonIcon
                phone={phoneNumberSecondary}
                formattedPhone={phoneNumberFormatted(phoneNumberSecondary, maskRegex, mask)}
                componentClasses={componentClasses}
                title={contact.tooltipPhone}
              />
            </div>
          </div>
        )}

        <div className={componentClasses.contactContainer}>
          <Typography variant="body2" color="textSecondary">
            {contact.email}
          </Typography>
          {emails &&
            emails.slice(0, 1).map(({ email, sequence }) => (
              <Typography variant="body1" key={sequence}>
                {email?.toLowerCase()}
              </Typography>
            ))}
        </div>
      </div>
    </CustomCard>
  )
}

const TooltipButtonIcon = ({ phone, formattedPhone, componentClasses, title }) => (
  <Tooltip title={<Typography variant="body2">{title}</Typography>} enterTouchDelay={100} arrow>
    <IconButton
      data-testid={phone?.sequence}
      className={componentClasses.contactCopyButton}
      onClick={() => navigator.clipboard.writeText(formattedPhone)}
    >
      <Icon name="outlined-action-copylink" size="tiny" />
    </IconButton>
  </Tooltip>
)
