export const genderCodes = {
  female: 2,
  male: 1,
  other: 3,
  transmale: 4,
  transfemale: 5,
  nonbinary: 6,
  notdeclared: 7,
  queer: 8,
}
