import React, { useContext, useState, useEffect } from 'react'
import {  PersonDataResponse } from '@/domain/use-cases/person'
import { GridItem, Typography } from '@naturacosmeticos/natds-web'
import { makeRegisterMaitenancePageApi } from '@/main/pages/register-maintenance/api/make-register-maintenance-page-api'
import { Page } from '@/domain/models/page/page'
import { IdentityInfoContext } from '@/main/contexts'
import {
  MyProfileMessages,
} from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { CnCreditDataResponse, CnCreditDataResponseError } from '@/domain/use-cases/person/minnegocio/get-cn-credit-data'
import { Card } from '@/main/components'
import { useMiGroupRedPage } from '@/main/hooks/useMiGroupRedPage'
import { useContactStyles } from './cn-credit.styles'

export type CnCreditDataProps = {
  personData: PersonDataResponse
  accessTokenNaturaWeb: string
}

export const CnCredit: React.FC<CnCreditDataProps> = ({ personData: { person }, accessTokenNaturaWeb }) => {
  const api = makeRegisterMaitenancePageApi()
  const [cnCredit, setCnCredit] = useState<CnCreditDataResponse>({})
  const { personId } = person
  const componentClasses = useContactStyles()
  const {
    companyId,
    countryId,
  } = useContext(IdentityInfoContext)
  const { credit } = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const { isMiGroupRedPage, creditMiGrupoRedData } = useMiGroupRedPage()

  useEffect(() => {
    getCnCreditData()
  }, [person])

  const getCnCreditData = async () => {
    if (isMiGroupRedPage) {
      setCnCredit({
        minimumOrder: creditMiGrupoRedData.minimumOrder,
        available: creditMiGrupoRedData.available,
        limit: creditMiGrupoRedData.limit,
      })
    return
  }

    const data = await api.getCnCredit({
      personId,
      countryId,
      companyId,
      authorization: accessTokenNaturaWeb
    }) as CnCreditDataResponse | CnCreditDataResponseError

    if ('error' in data) {
      return
    }

    setCnCredit({
      minimumOrder: data.minimumOrder,
      available: data.available,
      limit: data.limit,
      additional: data.additional
    })
  }
  return (
    <>
      <GridItem className="natds2" lg={4} md={4} sm={12} xs={12} data-testid="cn-credit">
        <Card data-testid="consultant-data" className={componentClasses.container}>
          <div className={componentClasses.content}>
            <Typography variant="body2">
              {credit.minimumOrder}
            </Typography>
            <div className={componentClasses.textContent}>
              <Typography variant="subtitle1">
                {cnCredit.minimumOrder || '-'}
              </Typography>
            </div>
          </div>
        </Card>
      </GridItem>
      <GridItem className="natds2" lg={4} md={4} sm={12} xs={12}>
        <Card data-testid="consultant-data" className={componentClasses.container}>
          <div className={componentClasses.content}>
            <Typography variant="body2">
              {credit.availableCredit}
            </Typography>
            <div className={componentClasses.textContent}>
              <Typography variant="subtitle1">
                {cnCredit.available ?? '-'}
              </Typography>
            </div>
          </div>
        </Card>
      </GridItem>
      <GridItem className="natds2" lg={4} md={4} sm={12} xs={12}>
        <Card data-testid="consultant-data" className={componentClasses.container}>
          <div className={componentClasses.content}>
            <Typography variant="body2">
              {credit.totalCredit}
            </Typography>
            <div className={componentClasses.textContent}>
              <Typography variant="subtitle1">
                {cnCredit.limit || '-'}
              </Typography>
            </div>
          </div>
        </Card>
      </GridItem>
    </>
  )
}
